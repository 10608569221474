var $x = function(id, win) {
    return (win || window).document.getElementById(id);
};
$x.isV8 = function(win) {
    try { return win.location.host.toLowerCase().replace(/\:80|\:443/, "") == location.host.toLowerCase().replace(/\:80|\:443/, "") } catch (e) { return false; }
};
$x.top = function(wind) {
    if ($x.topw) return $x.topw;
    var win = wind || window;
    while (!win.__isKYTop && win.parent && win.parent != win && $x.isV8(win.parent)) {
        win = win.parent;
    }
    if (win.__isKYTop) { return $x.topw = win; }
    while (win.dialogArguments != null) {
        win = $x.top(win.dialogArguments);
    }
    return $x.topw = win;
};
$x.ajax = function(func, d, fnOk, fnError, thiso) {
    d.FuncName = func;
    return $x.top().ajaxSync.call(window, d, fnOk, fnError, thiso || window);
};
$x.ajaxAsync = function(func, d, fnOk, fnError, thiso) {
    d.FuncName = func;
    return $x.top().ajaxAsync.call(window, d, fnOk, fnError, thiso || window);
};
$x.ajaxMsg = function (func, d) {
    d.FuncName = func;
    $x.top().ajaxMsg.apply(this, [].slice.call(arguments, 1))
}
$x.tx = function (o) {
    return o.innerText || o.textContent || "";
}
$x.toJSON = function(o) {
    return $x.top().$.toJSON(o);
};
$x.appRoot = function() {
    if (!$x.appRootV) {
        var scripts = document.getElementsByTagName("script");
        for (var i = 0; i < scripts.length; i++) {
            var strJsPath = scripts[i].src.split('?')[0].toLowerCase();
            var pos = strJsPath.indexOf('/scripts/');
            if (pos > -1) {
                $x.appRootV = scripts[i].src.split('?')[0].substring(0, pos + 1);
                break;
            }
        }
        if ($x.appRootV.indexOf(":") == -1 && $x.appRootV.substr(0, 1) != "/") {
            $x.appRootV = $x.curPath() + $x.appRootV;
        }
    }
    return $x.appRootV;
};
$x.plusRoot = function () {
    return $x.appRoot() + '#/back'
}
$x.docWin = function(doc) {
    doc = doc || document;
    return doc.defaultView || doc.parentWindow;
};
$x.isIE = function(ver) {
    var tr = /(msie\s*|trident.*rv:)([\d]+)/i.exec(navigator.userAgent);
    var r = tr ? { v: parseInt(tr[2])} : false;
    return (r.v == ver || !ver) ? r : false;
};
$x.curPath = function(win) {
    if (!$x.curPathV) {
        var li = (win || window).location.href.split('?')[0].replace("\\", "/").lastIndexOf("/") + 1;
        $x.curPathV = (win || window).location.href.substring(0, li);
    }
    return $x.curPathV;
};
$x.toAbsUrl = function(relativeUrl, compareUrlWin) {
    if (!/^https?:|^javascript:/i.test(relativeUrl) && !/^\//.test(relativeUrl)) {
        if (typeof compareUrlWin != "string") {
            compareUrlWin = $x.curPath(compareUrlWin);
        }
        relativeUrl = compareUrlWin + relativeUrl;
    }
    return relativeUrl;
};
$x.keyCode = function(e) {
    var key = window.event ? e.keyCode : e.which;
    return key;
};
$x.queryString = function(name, search) {
    var rg = new RegExp("\\W" + name + "\\=([^&]*)", "ig");
    var r = rg.exec(search || location.search);
    if (r) {
        return RegExp.$1;
    }
    return "";
}

var rc = {};
rc.getElementById = function(id, win) {
    return $x(id, win);
};
rc.getElementsByName = function(name, win) {
    var oWindow = window;
    if (win) {
        oWindow = win;
    }
    return oWindow.document.getElementsByName(name);
};
rc.getValueById = function(id, win) {
    win = win || window;
    var oControl = $x(id, win);
    if (oControl) {
        if (oControl.tagName == "DIV" && String(oControl.contentEditable) == "true" && win.scEditor) {
            return String(win.scEditor.getValue(oControl));
        }
        var r = (oControl.value == undefined || oControl.value == "") ? oControl.getAttribute("cval") : oControl.value;
        return r == null ? "" : String(r);
    }
    else {
        return null;
    }
};
rc.setValueById = function(id, value, win) {
    win = win || window;
    var oControl = $x(id, win);
    if (oControl) {
        if (oControl.tagName == "DIV" && win.scEditor) {
            return oControl.innerHTML = value;
        }
        return oControl.value = value;
    }
};
rc.getLangValuesByName = function(name, win) {
    win = win || window;
    var ret = "";
    var oControls = win.$('#' + name + " input");
    for (var i = 0; i < oControls.length; i++) {
        var o = oControls[i];
        if (o.getAttribute('lgid')) {
            if (ret != "") ret += "`";
            ret += (o.getAttribute('lgid') + "`");
            ret += o.value;
        }
    }
    return ret;

};
rc.setLangValuesByName = function(name, values, win) {
    win = win || window;
    var oControls = win.$('#' + name + " input");
    var vs = values.split('`');
    for (var i = 0; i < oControls.length; i++) {
        var o = oControls[i];
        var setted = 0;
        for (var j = 0; j < vs.length - 1; j += 2) {
            if (o.attributes["lgid"].value == vs[j]) {
                o.value = vs[j + 1];
                setted = 1;
            }
        }
        if (!setted) o.value = "";
    }
};


var CookieUtil = new Object();
CookieUtil.getCookieVal = function(offset) {
    var endstr = document.cookie.indexOf(";", offset);
    if (endstr == -1) {
        endstr = document.cookie.length;
    }
    return unescape(document.cookie.substring(offset, endstr));
};
CookieUtil.GetCookie = function(name) {
    var arg = name + "=";
    var alen = arg.length;
    var clen = document.cookie.length;
    var i = 0;
    while (i < clen) {
        var j = i + alen;
        if (document.cookie.substring(i, j) == arg) {
            return CookieUtil.getCookieVal(j);
        }
        i = document.cookie.indexOf(" ", i) + 1;
        if (i == 0) {
            break;
        }
    }
    return null;
};
CookieUtil.DeleteCookie = function(name, path, domain) {
    if (CookieUtil.GetCookie(name)) {
        document.cookie = name + "=" +
		((path) ? "; path=" + path : "") +
		((domain) ? "; domain=" + domain : "") +
		"; expires=Thu, 01-Jan-70 00:00:01 GMT";
    }
};
CookieUtil.SetCookie = function (name, value, expires, path, domain, secure) {
    CookieUtil.DeleteCookie(name, "/");
    document.cookie = name + "=" + escape(value) +
    ((expires) ? "; expires=" + expires.toGMTString() : "") +
    ((path) ? "; path=" + path : "") +
    ((domain) ? "; domain=" + domain : "") +
    ((secure) ? "; secure" : "");
};
CookieUtil.SetLongCookie = function(name, value, path, domain, secure) {
    document.cookie = name + "=" + escape(value) +
    "; expires=Mon, 31 Jan 3200 16:00:00 UTC" +
    ((path) ? "; path=" + path : "") +
    ((domain) ? "; domain=" + domain : "") +
    ((secure) ? "; secure" : "");
};

var SessionUtil = {
    Expire: function () {
        location.href = $x.appRoot() + 'AppLogin.aspx?targetUrl=' + encodeURIComponent($x.appRoot() + 'index.html#/')
    }
};

var maskUtil = {
    init: function() {
        if (!this.inited) {
            this.inited = 1;
        }
    },
    show: function(content) {
        this.init();
        showLoader();
    },
    hide: function() {
        hideLoader();
    }
}

function jPushMsg(id)
{
   var source=$x.queryString ("source",window.location.href);        
   if(source){   	
        var target= $("#"+id);
        $(target).removeAttr("data-rel");
 	    $(target).removeAttr("onclick");
        $(target).on("click",function(){                
            window.location.href = $x.plusRoot();            
        });            
   }               
}

function encode64(data) {
    return btoa(unescape(encodeURIComponent(data)))
}
function decode64(data) {
    return decodeURIComponent(escape(atob(data)))
}

function addQueryStr(url, queryStrObj) {
    var qs = Object.keys(queryStrObj).map(function (key) {
        return key + '=' + encodeURIComponent(queryStrObj[key])
    }).join('&');
    return url + (url.indexOf('?') > -1 ? '&' : '?') + qs
}

function urlAddVer(url) {
    var p = { v: $x.top()._sVersion }
    if (url.match(/\.aspx/)) {
        p.lgid = $x.top()._lgid
        p._cv = localStorage.getItem('cacheVer')
    }
    return addQueryStr(url, p);
}

function addHeadFiles() {
    var ret = ''
    for (var i = 0, j = arguments.length; i < j; i++) {
        var file = $x.appRoot() + urlAddVer(arguments[i]);
        if (file.match(/\.css/i)) {
            ret += '<link href="' + file + '" type="text/css" rel="stylesheet" />\n'
        } else {
            ret += '<script src="' + file + '"></script>\n'
        }
    }
    document.write(ret);
}

if (!$x.top()._KYTK) {
    $x.top()._KYTK = 1;
    addHeadFiles('Scripts/App/GetReqToken.aspx');
}
